@use "./styles/_buttons.scss";
@use "./styles/_margins.scss";
@use "./styles/_alignments.scss";
@use "./styles/_fonts.scss";

// input font color
$font-color-blue: #060f9b;
$font-hover-color-blue: rgba(5, 15, 155, 0.75);

// gray input
$input-placeholder-color: rgba(0, 0, 0, 0.31);
$input-font-color: rgba(0, 0, 0, 0.69);
$input-border-color: rgba(0, 0, 0, 0.31);
$input-border-color-hover: rgba(0, 0, 0, 0.41);
$input-border-color-focus: rgba(0, 0, 0, 0.51);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="tel"],
input[type="email"],
select,
.default-input {
  height: 42px;
  border: 0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px;
  font-style: normal;
  font-family: "Inter", sans-serif;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px;
  border: 1px solid $input-border-color;
  background-color: #d9d9d954;
  letter-spacing: 0.2px;
  color: $input-font-color;
}

select {
  height: 42px;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  font-weight: 400;
  border: 1px solid $input-border-color;
  background-color: #d9d9d954;
  color: $input-font-color;
}

option {
  color: rgba(0, 0, 0, 0.85);
}

textarea {
  height: 100px;
  border: 0;
  border-radius: 5px;
  padding: 8px;
  font-style: normal;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  border: 1px solid $input-border-color;
  background-color: #d9d9d954;
  letter-spacing: 0.2px;

  @media (max-width: 500px) {
    width: 100%;
  }
}

input:focus,
input[type="text"]:focus,
[type="number"]:focus,
[type="password"]:focus,
[type="date"]:focus,
[type="tel"]:focus,
[type="email"]:focus,
select:focus,
textarea:focus,
.default-input:focus {
  outline: 1px solid rgba(6, 15, 155, 0.75);
  border: unset;
  outline-offset: 0;
  color: $input-font-color;
}

input:hover,
input[type="text"]:hover,
[type="number"]:hover,
[type="password"]:hover,
[type="date"]:hover,
[type="tel"]:hover,
[type="email"]:hover,
select:hover,
textarea:hover,
.default-input:hover {
  background-color: rgba(255, 255, 255, 0.93);
}

::placeholder {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.31) !important;
}

// ! Caso input tenha algo preenchido, muda a cor.
// ! O problema aqui é que o select nao tem essa função para mudar a cor.
// input:not([value=""]) {
//   background-color: #fff;
//   border: .5px solid gray;
// }

h2 {
  font-size: 22px;
  font-weight: 500;
}

.whatsapp {
  position: absolute;
  cursor: pointer;
  right: 30px;
  bottom: 50px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

label {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.69);
}

.Toastify__toast-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
}

button {
  font-weight: 500;
}

.slick-slide > div {
  display: grid;
  place-items: center;
  margin: auto;
  padding: 10px;
}

.slick-prev:before,
.slick-next:before {
  color: $font-color-blue;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.16);
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #44444450;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #444444;
}
