//HOME STUDENT
$bg--color--main: #fff;
$font-color: rgba(0, 0, 0, 0.69);

//SIDEBAR SIZE
$sidebar--size: 280px;

// * breakpoints:
$mobile: 430px;
$tablet: 768px;
$desktop: 1024px;

.main {
  display: flex;
  flex-direction: column;
  background-color: $bg--color--main;
  min-height: 100%;
  width: 100%;
  padding: 15px;

  @media (min-width: $desktop) {
    min-height: 100vh;
    height: calc(100vh - 30px);
  }

  @media (min-width: $tablet) {
    padding: 15px 15px 15px 0;
    margin-left: $sidebar--size;
    width: calc(100% - $sidebar--size);
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 30px);
  margin-top: 60px;
  background-color: #f1f5fe;
  border-radius: 12px;
  overflow-y: auto;
  padding: 20px;
  gap: 30px;
  color: $font-color;

  @media (max-width: $tablet) {
    padding-bottom: 30px;
  }

  @media (min-width: $tablet) {
    margin-top: 0;
  }
}

.video__container {
  width: 100%;
  height: 100%;
  background-color: green;
}

.content__navigation {
  min-width: 200px;
  height: 100%;
  background-color: red;
}

.box__curso {
  height: 100px;
  width: 100%;
  border-left: 0.5px solid gray;
  border-top: 0.5px solid gray;
  border-right: 0.5px solid gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
}

.box__curso:last-child {
  border-bottom: 0.5px solid gray;
}

.disabled {
  cursor: not-allowed;
}

.difficulty {
  display: flex;

  @media (max-width: $mobile) {
    display: none !important;
  }
}
