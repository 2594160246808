.main-body {
  display: flex;
  height: 100%;
  width: 100%;
}

.inactive,
.active,
.inactive2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100% !important;
}

.inactive:hover,
.inactive2:hover {
  text-decoration: underline;
}

.inactive {
  color: #0e119c;
  font-weight: 600;
}

.inactive2 {
  font-weight: 600;
}

.active {
  font-weight: 600;
  text-decoration: underline;
}

.active:focus,
.nav-active:focus,
.inactive:focus,
.inactive2:focus {
  box-shadow: none !important;
}

.active:hover,
.nav-active:hover,
.inactive:hover,
.inactive2:hover {
  box-shadow: none !important;
}



.nav,
.nav-active {
  margin-top: 2px !important;
}

.nav {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.nav-active {
  font-weight: 600;
  text-decoration: underline;
  text-align: center;
  font-size: 16px;
  height: 60px;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-self: center;
  height: calc(100% - 80px);

  @media (max-width: 1200px) {
    margin-left: 0;
  }
}

.full-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 60px;
}

.open-plan-modal {
  display: flex;
  width: 100%;

  .button-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .open-modal-button {
      background-color: #AF0202;
      cursor: pointer;
      border: 0;
      color: white;
      width: 90%;
      height: 35px;
      padding: 4px;
      text-align: center;
      border-radius: 12px;
    }
  }
}

// glowing animation
@keyframes glowing {
  0% {
    background-color: #AF0202;
    box-shadow: 0 0 4px #AF0202;
  }

  50% {
    background-color: #aa1b1b;
    box-shadow: 0 0 10px #aa1b1b;
  }

  100% {
    background-color: #AF0202;
    box-shadow: 0 0 4px #AF0202;
  }
}

.open-modal-button {
  animation: glowing 2000ms infinite;
}


.bell {
  display: block;
  margin-top: 3px;
}

.menu-item {
  width: 100%;
  color: #0e119c;
}

.menu-item:hover {
  text-decoration: underline;
}

.menu-item2 {
  color: #fff;
}

.menu-nav-item:hover {
  background-color: rgba(14, 17, 156, 0.17);
}

.menu-nav-item {
  align-items: flex-start !important;
  color: #0e119c !important;
}

.drawer {
  display: none !important;
}

.navbar-container {
  display: flex;
  position: fixed;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  z-index: 3;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7.7px);
  -webkit-backdrop-filter: blur(7.7px);
  border: 1px solid rgba(255, 255, 255, 0.21);
}

.nav-holder {
  justify-content: space-between;
}


@media (max-width: 900px) {
  .nav-container {
    justify-content: space-between;
  }

  .nav-holder {
    width: unset !important;
    justify-content: flex-end;
  }

  .ghost-div,
  .bell {
    display: none !important;
  }

  .drawer {
    display: flex !important;
    align-self: right;
  }
}


// NEW NABAR
$bg--color--main: #fff;

//SIDEBAR SIZE
$sidebar--size: 280px;

// * breakpoints: 
$mobile: 430px;
$tablet: 768px;
$desktop: 1024px;

.main__body {
  position: fixed;
  display: flex;
  flex-direction: row;
  height: 60px;
  background-color: $bg--color--main;
  padding-bottom: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;
  border-right: rgba(14, 17, 156, 0.17);

  @media (max-width: $mobile) {
    position: relative;
    z-index: 5;
  }

  @media (min-width: $tablet) {
    width: $sidebar--size;
    min-height: 100%;
    padding: 70px 0 3% 0;
  }
}

.responsive__navbar {
  display: flex;
  background-color: $bg--color--main;
  padding: 15px;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #7E7E7E60;
  box-shadow: 0 3px 5px #00000050 !important;
  position: fixed;
  z-index: 10;
  

  @media(min-width: $tablet) {
    display: none;
  }

  @media (min-width: $tablet) {
    width: $sidebar--size;
  }
}

.navbar__container {
  display: none;
  flex-direction: column;
  // justify-content: space-between;
  font-weight: 600;
  gap: 10px;
  height: 100%;
  z-index: 3;

  width: 60px;

  @media (min-width: $tablet) {
    width: $sidebar--size;
  }

  @media(min-width: $tablet) {
    display: flex;
  }
}

.nav__holder {
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.navs {
  width: 100%;
  gap: 60px;
  font-size: 14px;
  align-items: flex-start;
  color: #06097D;

  .a {
    width: 100%;
    height: 32px;
  }

  .free-content {
    width: 100%;
    align-self: center;
    height: 100%;
  }

}

.active__marker {
  width: 7px;
  height: 32px;

  background: #0043F0;
  box-shadow: 0px 0px 7px #0057FF;
  border-radius: 5px;
  transform: matrix(-1, 0, 0, 1, 0, 0);

  flex: none;
  order: 0;
  flex-grow: 0;
}


.backups {
  width: 100%;

}

.main__content {
  display: flex;
}

.full__page {
  height: 100%;
}