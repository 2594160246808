.mt-sm {
  margin-top: 10px;
}

.mt-md {
  margin-top: 25px;
}

.mt-lg {
  margin-top: 50px;
}

.mt-xl {
  margin-top: 100px;
}

.mb-sm {
  margin-bottom: 10px;
}

.mb-md {
  margin-bottom: 25px;
}

.mb-lg {
  margin-bottom: 50px;
}

.mb-xl {
  margin-bottom: 100px;
}

.ml-sm {
  margin-left: 10px;
}

.ml-md {
  margin-left: 10px;
}

.ml-lg {
  margin-left: 10px;
}

.ml-xl {
  margin-left: 10px;
}

.mr-sm {
  margin-right: 10px;
}

.mr-md {
  margin-right: 25px;
}

.mr-lg {
  margin-right: 50px;
}

.mr-xl {
  margin-right: 100px;
}