.menu-item {
  display: flex;
  align-items: center;
  width: 100%;
  color: #0e119c !important;
}

.menu-item:hover {
  text-decoration: underline;
}

.menu-item-drawer {
  width: 100%;
}

.menu-item-drawer:hover {
  text-decoration: underline;
}

.pocontent {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.active__marker {
  width: 7px;
  height: 32px;

  background: #0043F0;
  box-shadow: 0px 0px 7px #0057FF;
  border-radius: 5px;
  transform: matrix(-1, 0, 0, 1, 0, 0);

  flex: none;
  order: 0;
  flex-grow: 0;
}