.counter-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
  border-top: .5px solid white;
  border-bottom: .5px solid white;
  width: 100%;

  .user-counter {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-align: start;
    padding: 20px 0;

    p {
      width: 100%;
      padding-left: 15px;
    }

    @media (max-width: 700px) {
      padding: 5px 10px;
      font-size: 12px;
    }
  }

}