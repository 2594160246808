.menu-item {
  padding: 10px 0 5px 15px;
  font-weight: 500;
  width: 100%;
  color: #0e119c;
}

.menu-item:hover {
  text-decoration: underline;
}

.menu-item-drawer {
  width: 100%;
}

.menu-item-drawer:hover {
  text-decoration: underline;
}

.pocontent {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}