// new platform
// * breakpoints: 
$mobile: 430px;
$tablet: 768px;
$desktop: 1024px;

// * button white
$button--color: #0E119C;
$button--shadow--color: rgba(14, 17, 156, .34);
$button--bg: #fff;

// * button blue
$button--color--2: #fff;
$button--shadow--color--2: rgba(14, 17, 156, .34);
$button--bg--2: #0E119C;

// * general button stuff
$border--radius: 9px;

// * NEW DEFAULT JUNE-23
// button weights
$btn-500: 500;

//  button colors
$btn-blue: #0E119C;
$btn-red: #0E119C;
$btn-white: #0E119C;

// background color
$btn-bg-transparent: none;
$btn-bg-blue: #0E119C;
$btn-bg-white: #fff;

// fontsize
$btn-font-sm: 12px;
$btn-font-md: 14px;
$btn-font-lg: 16px;










.btn__white {
  color: $button--color !important;
  background-color: $button--bg !important;
  border-radius: $border--radius !important;
}

.btn__white__border {
  color: $button--color !important;
  background-color: $button--bg !important;
  border-radius: $border--radius !important;
  border: .5px solid $button--color;
}

.btn__shadow__blue {
  box-shadow: 0px 2px 8px $button--shadow--color;
}



.btn__blue {
  color: $button--color--2 !important;
  background-color: $button--bg--2;
  border-radius: $border--radius !important;
}


// old platform
.btn {
  height: 40px;
  width: 200px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
}

.btn-sm {
  height: 40px;
  width: 100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 5px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
}

.btn-blue {
  border: none;
  background: #060F9B;
  color: white;
  font-weight: 600;
}

.btn-red {
  border: none;
  background: #AF0202;
  color: white;
}

.btn-blue-teacher {
  height: 40px;
  width: 170px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  border: none;
  background: #060F9B;
  color: white;
}

.btn-red-outline-teacher {
  height: 40px;
  width: 170px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  background: #F9F9F9;
  border: 0.5px solid #AF0202;
  color: #AF0202;
}

.btn-blue-outline {
  background: #F9F9F9;
  border: 0.5px solid #060F9B;
  color: #060F9B;
}

.btn-blue-outline-alt {
  background: #F9F9F9;
  border: 0.5px solid #fff;
  color: #060F9B;
}

.btn-blue-outline-alt2 {
  background: #fff;
  border: 0.5px solid #fff;
  color: #0e119c;
  font-weight: 600;
}

.btn-red-outline {
  background: #F9F9F9;
  border: 0.5px solid #AF0202;
  color: #AF0202;
}

.btn-red {
  background: #AF0202;
  border: 0.5px solid #AF0202;
  color: #ffff;
}

.btn-disabled {
  border: none;
  background: #7F8185;
  color: white;
  cursor: not-allowed;
}

.btn-disabled-opacity {
  cursor: not-allowed;
  opacity: 0.4;
}

.btn-plan-blue {
  font-weight: 500;
  font-size: 14px;
  border: none;
  height: 60px;
  padding: 0 10px;
  width: 230px;
  background-color: #060F9B;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  color: #FDFDFD;
}

.btn-plan {
  font-weight: 500;
  font-size: 14px;
  border: none;
  height: 60px;
  padding: 0 10px;
  width: 230px;
  height: 60px;
  background: #F9F9F9;
  border: 0.5px solid #D1D2E3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  color: #060F9B;
}

.btn-prev,
.btn-close,
.btn-next {
  height: 40px;
  font-weight: 500;
  border-radius: 7px;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

.btn-prev {
  color: #fff;
}

.btn-next,
.btn-close {
  background-color: #060F9B;
  color: #fff;
  border-radius: 7px;
}

.btn-next:hover,
.btn-blue:hover,
.btn-blue-outline:hover {
  background-color: rgba(6, 15, 155, .5);
}

@media (max-width: $tablet) {

  .btn-prev,
  .btn-close,
  .btn-next {
    font-size: 14px;
  }
}