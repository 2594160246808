.toast {
  display: flex;
  justify-content: center;
  padding: 10px;
  max-height: 100px;

  .toastText {
    position: relative;
    align-self: center;

    p {
      font-weight: 600;
      color: #00010D;
      word-wrap: break-word;
      width: 220px;
      margin-left: 40px;
      font-size: 14px;
    }
  }


  img {
    display: flex;
    align-self: center;
    width: 45px;
    height: 45px;
  }
}