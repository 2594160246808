$mobile: 430px;
$tablet: 768px;
$desktop: 1024px;

.header {
  width: 100%;
  padding-left: 30px;
  background-color: #fff;
  position: fixed;
  z-index: 5;
  height: 40px;
  border-bottom: 1px solid #7E7E7E60;
  box-shadow: 0 3px 5px #00000050;

  @media(max-width: calc($tablet - 1px)) {
    display: none !important;
  }
}