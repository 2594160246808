.notification {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  height: 120px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: -2px 1px 2px rgba(0, 0, 0, 0.25);
  position: relative;
  outline: 0.5px solid lightgray;
  z-index: 1;
}


.close-btn {
  width: 25px;
  height: 25px;
  position: absolute;
  border-radius: 5px;
  right: 5px;
  top: 5%;
  transform: translateY(-5%);
  z-index: 99;
}

.close-btn:hover {
  background-color: rgb(255, 255, 255);
}

.close-icon {
  width: 20px;
  height: 10px;
}

.default {
  background-color: #d4d4fd;
}

.read {
  background-color: #fff;
}

.default:hover {
  background-color: #b5b5fd;
}

.read:hover {
  background-color: #e7e7e7;
}