.bell {
  cursor: pointer;
}

.btn-bell {
  position: relative;
}

.unread {
  line-height: 1em;
}

.unread__drawer {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: #FF1616;
  text-align: center;
  color: white;
  font-size: 11px;
  padding: 1px 4px;
  border-radius: 50%;
  line-height: 22px;
  min-width: 24px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #060F9B;
}

.footer-btn {
  border-radius: 5px;
}

.footer-btn p {
  font-size: 12px;
  color: #fff;
  word-wrap: break-word;
  text-decoration: none;
}

.footer-btn:hover {
  background-color: #b5b5fd;
  border-radius: 5px;
}

.notification-central-btn {
  cursor: pointer;
}

.notification-central-btn:hover {
  color: #fff;
  background-color: #060f9b;
  border-radius: 5px;
}

.notifications-link {
  background-color: rgb(24, 168, 24);
}

.notifications-link:hover {
  background-color: rgb(18, 119, 18);
}

.active__marker {
  width: 7px;
  height: 32px;

  background: #0043F0;
  box-shadow: 0px 0px 7px #0057FF;
  border-radius: 5px;
  transform: matrix(-1, 0, 0, 1, 0, 0);

  flex: none;
  order: 0;
  flex-grow: 0;
}